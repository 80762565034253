<template>
  <base-modal :id="partnerModalName" :full="true">
    <add-edit-page>
      <template #header="{ validation }">
        <sticky-header title="Edit Driver">
          <h4>{{ form.profile.firstName }} {{ form.profile.lastName }}</h4>
          <div class="document-toolbar">
            <div class="document-actions">
              <div class="button-group">
                <button v-if="editingEnabled" class="button" @click="enableEditing()">Edit</button>
                <button v-if="!editingEnabled" class="button"
                  :disabled="checkValidation(validation) || validateDates(validation) || ageValidationMessage || licStateError || licNumberError || emailError || phoneError || poError || ssnError || poError1 || postError || lengthError"
                  @click="handleSubmit(validation)">Save</button>
                <a class="button secondary" @click="cancel(validation, true)">Cancel</a>
              </div>
              <p class="message-align" :class="update.success ? 'success' : 'error'" v-if="displayMessage">{{
                update.message }}</p>
            </div>
          </div>
        </sticky-header>
      </template>
      <template>
        <div class="grid">
          <div class="col-7">
            <div class="spread">
              <div class="module-form">
                <h3>BASIC INFO</h3>
                <div class="para-group">
                  <form-input v-model="form.profile.firstName" v-if="getRole !== 'COORDINATOR'"
                    :disabled="editingEnabled" name="First Name " label="First Name" placeholder="First Name" required
                    rules="specialChar|trailSpaces|hasNum|min:2|max:50" />
                  <form-input v-model="form.profile.lastName" v-if="getRole !== 'COORDINATOR'"
                    :disabled="editingEnabled" label="Last Name" placeholder="Last Name"
                    rules="specialChar|trailSpaces|hasNum|min:2|max:50" required />
                  <form-input v-model="form.profile.phone" :disabled="editingEnabled" name="Phone"
                    v-maska="'1(###) ###-####'" label="Phone Number" type="text" @input="phoneCheck" rules="phone"
                    placeholder="Phone Number" required />
                  <p class="error-message" v-if="phoneError">Phone Number already exists</p>
                  <p class="error-message" v-if="lengthError">Invalid Phone Number</p>
                  <form-input v-model="form.profile.communication_email" :disabled="is_DMS_id" name="Email"
                    label="Communication Email" type="email" @input="emailCheck" placeholder="Communication Email"
                    required />
                  <p class="error-message" v-if="emailError">Email Address already exists</p>
                </div>
              </div>
              <div class="module-form" v-if="partner.address1 && partner.postalCode">
                <h3>ADDRESS</h3>
                <!-- <div class="para-group">
                  <form-input v-model="form.address.address1" :disabled="editingEnabled" name="address"  label="Address1" @input="poCheck1" required rules="trailSpaces" />
                <div v-if="showThis && addressSuggestions.length > 0" :class="{'suggestBlock':editingEnabled}" class="box">
                    <div v-for="(item, index) in addressSuggestions" :key="index">
                      <p class="suggestion-item" @click="setAddress(item.item)">{{ item.name }}</p>
                    </div>
                  </div>
                  <form-input v-model="form.address.address2" maxlength="100" name="address" @input="poCheck" label="Address2" rules="trailSpaces"
                    :disabled="editingEnabled" />
                    <p class="error-message" v-if="poError">Please enter a valid home address other than PO box</p>
                  <form-input v-model="form.address.city" name="city" label="City" required
                    rules="trailSpaces|addressText" :disabled="disableAddress || editingEnabled" />
                  <div class="field">
                    <label>State*
                      <form-input v-model="maskState" name="state" rules="trailSpaces" v-if="editingEnabled" disabled />
                      <region-select v-if="!editingEnabled" v-model="form.address.state" :country="form.address.country"
                        :region="form.address.state" placeholder="Select State" autocomplete disable-placeholder
                        :disabled="disableAddress" />
                    </label>
                  </div>
                  <form-input v-model="form.address.postalCode" name="zip" label="Zip" required rules="zip"
                    :disabled="disableAddress || editingEnabled" />
                  <div class="field">
                    <label>Country*
                      <form-input v-model="maskState" name="state" rules="trailSpaces" v-if="editingEnabled" disabled />
                      <country-select v-if="!editingEnabled" v-model="form.address.country"
                        :country="form.address.country" :white-list="['US']" top-country="US" autocomplete
                        :disabled="disableAddress || editingEnabled" />
                    </label>
                  </div>
                </div> -->

                <div>
                  <form-input v-model="form.address.address1" :disabled="editingEnabled" name="address" label="Address1"
                    @input="getAddressSuggestions($event)" required />
                  <div class="box" v-if="addressSuggestions.length > 0">
                    <div v-for="(item, index) in addressSuggestions" :key="index">
                      <p class="suggestion-item" @click="setAddress(item.item)">{{ item.name }}</p>
                    </div>
                  </div>
                  <form-input v-model="form.address.address2" name="address2" label="address2"
                    :disabled="editingEnabled" />
                  <form-input v-model="form.address.city" name="city" label="City" required :disabled="true" />
                  <div class="field">
                    <form-input v-model="form.address.state" name="state" label="state" required :disabled="true" />
                  </div>
                  <form-input v-model="form.address.postalCode" name="postalCode" @input="postLength"
                    label="Postal code" required :disabled="postalEnabled" />
                  <p v-if="postError" class="error-message">Postal code should be of 6 characters</p>
                </div>
              </div>
              <div class="module-form" v-if="licenseExist">
                <h3>DRIVERS LICENSE</h3>
                <div class="field">
                  <!-- <label>Drivers License State -->
                  <!-- <form-input v-model="maskState" name="state" rules="trailSpaces" v-if="editingEnabled" disabled />
                    <region-select v-if="!editingEnabled" v-model="form.license.state" :disabled="editingEnabled"
                      :region="form.license.state" placeholder="Drivers License State" autocomplete disable-placeholder
                      @input="validateLicenseState" /> -->
                  <form-select header.type='select' key="province" className="state-sel"
                    :value="candidateDetails[provinces]" label="Drivers License State" required
                    v-model="form.license.state" default-choice="Select" :options="provinces" option-label="name"
                    option-value="name" rules="required" :disabled="editingEnabled"
                    @input="onSelectingProvince($event)" />
                  <!-- </label> -->
                  <p class="error-message" v-if="licStateError">License state doesn't match with the number</p>
                </div>
                <form-input v-model="form.license.number" :disabled="editingEnabled"
                  placeholder="Drivers License Number" name="licenseNumber" label="Drivers License Number" ref="license"
                  :checkDriverNumber="driverNumberTest" @input="inputLicense" />
                <p class="error-message" v-if="licNumberError">{{ errorLicense }}</p>
                <p class="label margin-bottom-0">Drivers License Expiration</p>
                <div class="field">
                  <Datepicker v-model="form.license.expiration" :format="editingEnabled ? '**** *** **' : 'yyyy-MMM-dd'"
                    @selected="() => validator = false" :disabled="editingEnabled" :disabled-dates="disabledDates">
                  </Datepicker>
                </div>
              </div>
              <div class="module-form"
                v-if="(personalIdent && getRole !== 'ONBOARDING_SPECIALIST') && getRole !== 'COORDINATOR'">
                <h3>PERSONAL IDENTIFYING</h3>
                <div v-if="partner.dateOfBirth" class="dob-field-bottom">
                  <p class="label margin-bottom-0">Date of Birth</p>
                  <div class="field">
                    <Datepicker v-model="form.profile.dateOfBirth" :disabled="editingEnabled"
                      :format="editingEnabled ? '**** *** **' : 'yyyy-MMM-dd'" @selected="dateCheck"></Datepicker>
                  </div>
                  <p class="error-message" v-if="ageValidationMessage">Age must be above 18 years</p>
                </div>
                <form-input v-if="einExist" v-model="form.profile.ein" :disabled="editingEnabled" placeholder="EIN"
                  type="text" name="EIN" :rules="'length:10'" label="EIN" required />
                <form-input v-if="partner.ssn && editingEnabled" :value="'***-**-' + this.partner.ssn.slice(-4)"
                  :disabled="editingEnabled" placeholder="SSN" type="text" label="Social Insurance Number" />
                <form-input v-if="partner.ssn && !editingEnabled" v-model="form.profile.ssn" :disabled="editingEnabled"
                  maxLength="9" placeholder="SSN" type="text" name="SSN" @input="checkSsn"
                  label="Social Security Number" required />
                <p class="error-message" v-if="ssnError">{{ ssnErrorMsg }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </add-edit-page>
  </base-modal>
</template>

<script>
import StickyHeader from "@/components/layout/StickyHeader";
import FormInput from "@/components/forms/fields/FormInput";
import AddEditPage from "@/components/layout/AddEditPage";
import BaseModal from "@/components/modals/BaseModal";
import micromodal from "micromodal";
import { FormSelect } from "@/components";
import { maska } from "maska";
import { restApi } from "@/http/http.config";
import Datepicker from "vuejs-datepicker";
import { DriversLicenseValidation } from "@/util/validator";
import { formatPhone, formatDate } from "@/filters";
import { encodeWithParam } from "@/util/Base64Encoding";
import Base64 from "base-64";
import { encrypt } from "@/filters";

export default {
  name: "PartnerProfileEdit",
  directives: { maska },
  components: { AddEditPage, FormInput, StickyHeader, BaseModal, Datepicker, FormSelect },
  data: function () {
    return {
      driverNumberTest: false,
      errorLicense: '',
      ageValidationMessage: false,
      is_DMS_id: true,
      editingEnabled: true,
      maskState: '******',
      showThis: false,
      postalEnabled: true,
      postError: false,
      form: {
        profile: {
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          communication_email: "",
          ein: "",
          ssn: "",
          dateOfBirth: "",
        },
        data: [
          { label: "Province", value: "", key: "licenseState", class: "cell10", editable: true, type: "select" },
        ],
        address: {
          address1: "",
          address2: "",
          city: "",
          state: "",
          postalCode: "",
          country: "CA",
          addressID: "",
          county: ""
        },
        license: {
          state: "",
          number: "",
          expiration: "",
          id: ""
        },
      },
      provinces: [
        { name: "Alberta" },
        { name: "British Columbia" },
        { name: "Manitoba" },
        { name: "New Brunswick" },
        { name: "Newfoundland and Labrador" },
        { name: "Northwest Territories" },
        { name: "Nova Scotia" },
        { name: "Nunavut" },
        { name: "Ontario" },
        { name: "Prince Edward Island" },
        { name: "Quebec" },
        { name: "Saskatchewan" },
        { name: "Yukon" }
      ],
      displayMessage: false,
      update: {
        success: "",
        message: ""
      },
      validator: true,
      formError: false,
      licStateError: false,
      licNumberError: false,
      addressSuggestions: [],
      timeout: "",
      disableAddress: false,
      emailError: false,
      phoneError: false,
      lengthError:false,
      ssnError: false,
      poError: false,
      poError1: false,

    };
  },
  props: {
    partnerModalName: {
      type: String,
      required: false,
      default: "editPartnerModal",
    },
    partner: {
      type: Object,
      required: true,
      default: () => { }
    },
    partnerId: {
      required: true,
      default: "",
    }
  },
  computed: {
    einExist() {
      return this.$props.partner?.businessDetails ? this.$props.partner?.businessDetails[0]?.ein ? true : false : false;
    },
    licenseExist() {
      return this.$props.partner?.driverLicense ? this.$props.partner?.driverLicense[0]?.driverLicense ? true : false : false;
    },
    disabledDates() {
      return { to: new Date(Date.now() + 45 * 24 * 60 * 60 * 1000)}
    },
    searchInput() {
      return this.form.address.address1;
    },
    personalIdent() {
      return this.$props.partner.ssn || this.$props.partner.dateOfBirth || this.einExist
    },
    getRole() {
      return localStorage.getItem('role');
    }
  },
  watch: {
    partnerId() {
      this.prefillForm();
    },
    searchInput(newText, oldText) {
      if (oldText !== "") {
        if (newText !== oldText) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          // const value = newText;
          // this.getAddressSuggestions(value);
        }, 1000);
      }
    },
  },
  methods: {
    dateCheck(val) {
      this.validator = true
      var today = new Date();
      var birthDate = new Date(val);
      var age = today.getFullYear() - birthDate.getFullYear();
      let monthDifference = today.getMonth() - birthDate.getMonth();
      let dayDifference = today.getDate() - birthDate.getDate();

      if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
      }

      if (age < 18) {
        this.ageValidationMessage = true;
      } else {
        this.ageValidationMessage = false;
        return this.validator = false
      }
    },
    poCheck1(value) {
      this.addSugg();
      const restrictedAddress = ["pob", "pobo", "pobox", "bo", "box", "PO", "PO", "BOX", "PO B", "PO BO", "P.O. BOX", "P.O BOX", "PO. BOX", "POBOX", "PO BOX", "PO    BOX", "P.O BO", "PO. B", "P O Box", " PO  BOX", "PO BOX", "PO  B", "PO  BO", "P O BOX", "P O B", "P OB", "P O B", "P.O. B", "PO B", "P O", "P O", "P.O", "P.O ", "P.O  ", " P.O", "  P.O", "P.O B", "P.O  B", "P.O.  BO", "P.O.  BOX", "P.O.  B", " P.O.", "  P.O.", "  P.O. B", "  P.O. BO", "  P.O.  BOX", "p.o", "po", "p.o.", " po", "  po", "  po box", "p o", " p.o.", "p o box", "P.o", "p.O", "Po", "p.O"];

      // if (restrictedAddress.includes(value.toUpperCase().trim())) {
      if (value.split(' ').some((strItem) => restrictedAddress.includes(strItem))) {
        this.poError1 = true

      } else {
        this.poError1 = false

      }
    },
    poCheck(value) {
      const restrictedAddress = ["pob", "pobo", "pobox", "bo", "box", "PO", "PO", "BOX", "PO B", "PO BO", "P.O. BOX", "P.O BOX", "PO. BOX", "POBOX", "PO BOX", "PO    BOX", "P.O BO", "PO. B", "P O Box", " PO  BOX", "PO BOX", "PO  B", "PO  BO", "P O BOX", "P O B", "P OB", "P O B", "P.O. B", "PO B", "P O", "P O", "P.O", "P.O ", "P.O  ", " P.O", "  P.O", "P.O B", "P.O  B", "P.O.  BO", "P.O.  BOX", "P.O.  B", " P.O.", "  P.O.", "  P.O. B", "  P.O. BO", "  P.O.  BOX", "p.o", "po", "p.o.", " po", "  po", "  po box", "p o", " p.o.", "p o box", "P.o", "p.O", "Po", "p.O"];

      // if (restrictedAddress.includes(value.toUpperCase().trim())) {
      if (value.split(' ').some((strItem) => restrictedAddress.includes(strItem))) {
        this.poError = true

      } else {
        this.poError = false

      }
    },
    validateDates(val) {
      return !val.pristine ? false : this.validator;
    },
    enableEditing() {
      this.editingEnabled = false;
      if (this.$props.partner.dms_id) {
        this.is_DMS_id = false;
      }
      this.prefillForm();
    },
    prefillForm() {
      this.form.profile.firstName = this.$props.partner.firstName;
      this.form.profile.lastName = this.$props.partner.lastName;
      this.form.profile.phone = formatPhone(this.$props.partner.phone);
      this.form.profile.email = this.$props.partner.email;
      this.form.profile.communication_email = this.$props.partner.communication_email;
      this.form.profile.dateOfBirth = this.$props.partner.dateOfBirth ? formatDate(this.$props.partner.dateOfBirth) : "";

      this.form.license.state = this.$props.partner?.driverLicense[0]?.state;
      this.form.license.expiration = this.$props.partner?.driverLicense[0]?.expiration ? formatDate(this.$props.partner.driverLicense[0].expiration) : "";
      this.form.license.number = this.$props.partner?.driverLicense[0]?.driverLicense;
      this.form.license.id = this.$props.partner?.driverLicense[0]?.id;
      this.form.address.state = this.$props.partner.state;
      this.form.address.address2 = this.$props.partner.address2;



      this.form.address.addressID = this.$props.partner.addressID;
      this.form.address.county = this.$props.partner.county;

      this.form.address.address1 = this.editingEnabled ? this.piiMasking(this.$props.partner.address1) : this.$props.partner.address1;
      this.form.address.address2 = this.editingEnabled ? this.piiMasking(this.$props.partner.address2) : this.$props.partner.address2;
      this.form.address.city = this.editingEnabled ? this.piiMasking(this.$props.partner.city) : this.$props.partner.city;
      this.form.address.postalCode = this.editingEnabled ? this.piiMasking(this.$props.partner.postalCode) : this.$props.partner.postalCode;
      this.form.license.number = this.editingEnabled ? this.piiMasking(this.$props.partner?.driverLicense[0]?.driverLicense) : this.$props.partner?.driverLicense[0]?.driverLicense;

      this.form.profile.ein = this.editingEnabled ? this.piiMasking(this.$props.partner?.businessDetails[0]?.ein) : this.$props.partner?.businessDetails[0]?.ein;
      if (this.$props.partner.ssn) {
        this.form.profile.ssn = this.editingEnabled ? this.piiMasking(this.$props.partner.ssn) : this.ssnReformat(this.$props.partner.ssn)
      }


    },

    piiMasking(value) {
      return value?.substring(0, value.length)?.replace(/[a-z\d/-]/gi, "*");
    },
    cancel(validation, reset) {
      micromodal.close(this.partnerModalName);
      document.body.style.overflow = "visible";
      validation.reset();
      this.editingEnabled = true;
      this.validator = true;
      this.licStateError = false;
      this.disableAddress = false;
      this.addressSuggestions = [];
      this.emailError = false
      this.ssnError = false
      this.phoneError = false
      if (reset) {
        this.prefillForm();
      }
    },
    checkValidation(val) {
      for (let err in val.errors) {
        if (val.errors[err].length > 0) {
          this.formError = true;
          return true
        }
      }
      this.formError = false;
      return false
    },
    async emailCheck(val) {
      const result = await restApi.post("/api/checkEmail", {
        param: Base64.encode(JSON.stringify({ email: val.toLowerCase() })),
      })

      const valueEntered = val.length > 0
      const emailExists = result.data.rowCount != 0
      const initialEmail = (this.$props.partner.communication_email == val)

      const emailTaken = emailExists && valueEntered && !initialEmail
      if (emailTaken) {
        this.emailError = true
      } else return this.emailError = false;
      if (this.form.profile.email == val) {
        this.emailError = false
      }
    },
    async phoneCheck(val) {
      val = val.replace(/\D/g, '')
      const result = await restApi.post("/api/checkPhone", { param: Base64.encode(JSON.stringify({ phone: val })) })

      const valueEntered = val.length > 0
      const phoneExists = result.data.rowCount != 0
      const initialPhone = (this.$props.partner.phone == val)
      const minLength = val.length > 9
      if(!minLength){
        this.lengthError = true
      }else{
        this.lengthError = false
      }

      const phoneTaken = valueEntered && phoneExists && !initialPhone
      if (phoneTaken) {
        this.phoneError = true
      } else return this.phoneError = false
    },

    ssnReformat(val) {
      let sanitizedSSN = val.replace(/\D/g, '');
      return `${sanitizedSSN.slice(0, 3)}-${sanitizedSSN.slice(3, 5)}-${sanitizedSSN.slice(5)}`;

    },

    async checkSsn(val) {
      this.ssnError = false;
      this.ssnErrorMsg = '';
      let sanitizedSSN = val.replace(/\D/g, '');
      const maxLength = 11;
      if (val.length <= maxLength) {
        this.ssnError = true;
        if (sanitizedSSN.length > 3 && sanitizedSSN.length <= 5) {
          this.form.profile.ssn = `${sanitizedSSN.slice(0, 3)}-${sanitizedSSN.slice(3)}`;
        } else if (sanitizedSSN.length > 5) {
          this.form.profile.ssn = `${sanitizedSSN.slice(0, 3)}-${sanitizedSSN.slice(3, 5)}-${sanitizedSSN.slice(5)}`;
        } else {
          this.form.profile.ssn = sanitizedSSN;
        }
      }
      else {
        this.form.profile.ssn = val.slice(0, maxLength)
      }
      if (val.length === maxLength) {
        let ssnCheck = await encrypt(val.replace(/\D/g, ''))
        const data = await restApi.post("/employee/checkSSN", encodeWithParam({ ssn: ssnCheck }));
        this.ssnError = false;
        if (Number(data.data.result.count) > 0) {
          this.ssnError = true;
          this.ssnErrorMsg = 'Duplicate SSN'
        }
      }
    },
    addSugg() {
      this.showThis = true
    },
    async handleSubmit(validation) {
      this.form.profile.firstName=this.form.profile.firstName.trim()
      this.form.profile.lastName=this.form.profile.lastName.trim()

      this.editingEnabled = true;
      const partnerObj = {
        partnerId: this.$props.partner.id,
        ...this.form
      }
      partnerObj.license.expiration = this.formatCalendarDate(partnerObj.license.expiration);
      partnerObj.profile.dateOfBirth = this.formatCalendarDate(partnerObj.profile.dateOfBirth);
      if (partnerObj.profile.ssn) {
        partnerObj.profile.ssnFour = partnerObj.profile.ssn.substr(partnerObj.profile.ssn.length - 4);
        partnerObj.profile.ssn = await encrypt(partnerObj.profile.ssn.replace(/-/g, ''))

      }
      setTimeout(() => {
        this.updateApiCall(partnerObj, validation);
      }, 1000)
    },
    async updateApiCall(partnerObj, validation) {
      let addID = partnerObj.address.addressID
      if (!this.formError) {
        // console.log(partnerObj,"");
        // console.log(partnerObj.address.addressID,"vatatvatavtav")
        await restApi.post(`/partner/updatePartner`, {
          "param": window.btoa(JSON.stringify({ ...partnerObj, role: localStorage.getItem('role') }))
        })
          .then(({ data }) => {
            console.log("update partner call")
            this.displayMessage = true;
            this.update.success = data.success;
            this.update.message = data.message;
            setTimeout(() => {
              this.displayMessage = false;
              this.update = { success: "", message: "" };
              data.success ? this.cancel(validation, false) : "";
              this.$emit("refetch");
              this.$emit("statusupdated");
              this.$router.go(0)
            }, 2000);
          })
          .then(() => {
            restApi.post('/onboard/updateAddress', encodeWithParam({
              id: addID,
              address1: this.form.address.address1,
              address2: this.form.address.address2,
              city: this.form.address.city,
              state: this.form.address.state,
              postalCode: this.form.address.postalCode,
              applicant_id: this.$props.partner.id,
              country: "CA"

            }))
          })
      }
    },
    trailSpaces(value) {
      const start = /^([ ]+)/
      const end = /([ ]+$)/
      return start.test(value) || end.test(value);
    },
    formatCalendarDate(value) {
      const date = new Date(value).toLocaleDateString('en-US');
      const [month, day, year] = date.split('/');
      return value ? [year, month, day].join('-') : null;
    },
    validateLicenseState() {
      const valid = DriversLicenseValidation(this.$refs.license._props.value, this.form.license.state);
      this.licStateError = !valid
    },
    onSelectingProvince(value) {
      this.candidateDetails["provinces"] = value == this.form.license.state == this.$refs.license._props.value;
      // const valid = DriversLicenseValidation(this.$refs.license._props.value, this.form.license.state);
      // this.licStateError = !valid
      console.log(value, "cvavav value")
    },
    candidateDetails() {
      return {
        licenseState: this.$refs.license._props.value,
      };
    },
    async updateDL(data) {
      if (this.$props.data.partnerID) {
        let obj = {
          // licenseNumber: this.candidateDetails.licenseNumber,
          licenseState: this.candidateDetails.licenseState,
          // licenseExpiry: this.candidateDetails.licenseExpiry,
          partnerID: this.$props.data.partnerID,
        };
        setTimeout(async () => {
          if (!this.formError) {
            let result = await restApi.post(`/partner/updateDL`, encodeWithParam(obj));
            if (result.data.success) {
              this.$emit("dlChange", obj);
              this.cancel(data);
            }
          }
        }, 1000);
      }
    },
    async inputLicense(value) {
      // console.log(value);
      await restApi.post(`/partner/checkValueExists`, {
        "param": window.btoa(JSON.stringify({ type: 'license', value: value }))
      }).then(({ data }) => {
        // console.log(data.result.exists,"result");
        // this.licNumberError = !data.result.exists;

        if (data.result.exists && value.length > 1) {
          this.driverNumberTest = true;
          this.licNumberError = true;
          this.errorLicense = "Drivers License Number already exists."
        } else {
          // const valid = DriversLicenseValidation(value, this.form.license.state);
          const regex = /^(?!-)[A-Za-z0-9]+([A-Za-z0-9]+)*$/;
          const valid = regex.test(value);
          if (valid && this.licStateError) {
            this.licStateError = false;
            this.licNumberError = false;
          } else if (value.length > 0 && !valid) {
            this.driverNumberTest = true;
            this.licNumberError = true;
            this.errorLicense = "License doesn't appear to be valid."
          } else if (value.length < 1) {
            this.licNumberError = true;
            this.errorLicense = "Drivers License Number is required*"
          }
          else if (value.length > 0) {
            let result = this.trailSpaces(value);
            if (result == true) {
              this.driverNumberTest = true;
              this.licNumberError = result;
              this.errorLicense = "Can't contain white space at the start or end"
            } else {
              this.driverNumberTest = false;
              this.licStateError = false;
              this.licNumberError = false;
            }
          }
          else {
            this.driverNumberTest = false;
            this.licStateError = false;
            this.licNumberError = false;
          }
        }
      })
    },

    // getAddressSuggestions(searchText) {
    //   if(searchText) {
    //   const searchParams = encodeWithParam({ search: searchText });
    //   const regex = new RegExp(/^(PO.*BOX*)/);
    //   this.addressSuggestions = [];
    //   const restrictedAddress = ["PO","BOX","PO B","PO BO","P.O. BOX", "P.O BOX","PO. BOX", "POBOX" ,"PO BOX","PO BOX","P.O BO","PO. B","P O Box"," PO BOX","PO BOX","PO B","PO BO","P O BOX","P O B","P OB","P O B","P.O. B","PO B","P O","P O","P.O","P.O ","P.O "," P.O"," P.O","P.O B","P.O B","P.O. BO","P.O. BOX","P.O. B"," P.O."," P.O."," P.O. B"," P.O. BO"," P.O. BOX","p.o","po","p.o."," po"," po"," po box","p o"," p.o.","p o box","P.o","p.O","Po","p.O"];
    //   if (!restrictedAddress.includes(searchText?.trim().toUpperCase()) && searchText.trim().toUpperCase().search(regex) === -1) { 
    //         restApi.post("/employee/getAddressSuggestions", searchParams)
    //     .then((data) => {
    //       if (data?.data?.result?.length > 0) {
    //         this.addressSuggestions = data?.data?.result.map((item) => {
    //           return {
    //             name: `${item.streetLine}, ${item.city}, ${item.state}, ${item.zipcode}`,
    //             item,
    //           };
    //         });
    //         this.disableAddress = true;
    //       } else {
    //         this.addressSuggestions = [
    //           {
    //             // name: "Unknown address, enter manually",
    //             name: "Please enter a valid home address other than PO box",
    //             item: {},
    //           },
    //         ];
    //         this.disableAddress = true
    //       }
    //     })
    //   } else {
    //         this.addressSuggestions = [
    //           {
    //             // name: "Unknown address, enter manually",
    //             name: "Please enter a valid home address other than PO box",
    //             item: {},
    //           },
    //         ];
    //         this.disableAddress = true
    //   }
    //   }
    // },
    // setAddress(data) {
    //   if (Object.keys(data).length > 0) {
    //     this.form.address.address1 = data.streetLine;
    //     this.form.address.address2 = data.secondary;
    //     this.form.address.city = data.city;
    //     //this.form.address.county = data.county;
    //     this.form.address.state = data.state;
    //     this.form.address.postalCode = data.zipcode;
    //   }
    //   this.addressSuggestions = [];
    // },
    getAddressSuggestions(searchText) {

      const searchParams = encodeWithParam({ inputval: searchText, type: 'address' });
      restApi.post("/employee/placeAutocomplete", searchParams)
        .then(({ data }) => {
          if (data.result.predictions.length > 0) {
            this.addressSuggestions = data.result.predictions.map((item) => {
              return {
                name: `${item.description}`,
                item: `${item.place_id}`,
              };
            });
            this.disableAddress = true;
          } else {
            this.addressSuggestions = [
              {
                name: "Unknown address, click here to enter manually",
                item: {},
              },
            ];
            this.disableAddress = false
          }
        })
    },
    setAddress(data) {
      const searchParams = encodeWithParam({ place_id: data });
      restApi.post("/employee/getPlaceDetails", searchParams)
        .then((data) => {
          let addressComponents = data.data.result.result.address_components;
          if (addressComponents.length > 0) {

            this.form.address.address1 = ((addressComponents.find(component => component.types.includes('street_number'))?.long_name || '') + ' ' + (addressComponents.find(component => component.types.includes('route'))?.long_name || '')).normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '');;
            this.form.address.city = (addressComponents.find(component => component.types.includes('locality'))?.long_name || '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            this.form.address.state = (addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name || '').normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '');
            this.form.address.postalCode = addressComponents.find(component => component.types.includes('postal_code'))?.short_name || '';
            this.addressSuggestions = [];
            if (this.form.address.postalCode.length < 6) {
              this.postalEnabled = false;
              this.postError = true;
            } else {
              this.postalEnabled = true;
              this.postError = false
            }
          }
        })
    },
    postLength(e) {
      if (e.length < 6 || e.length > 6) {
        this.postError = true;
      } else {
        this.postError = false
      }
    }
  },

};
</script>

<style scoped>
.date-pick-width {
  width: 424px;
}

.input-icon-max-width {
  padding-left: 90%;
}

.message-align {
  margin-top: 6px;
  margin-bottom: -12px;
}

.error {
  color: var(--error-40);
}

.success {
  color: var(--success-40);
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.dob-field-bottom {
  margin-bottom: 16px;
}

.box {
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  padding: 5px;
  font-family: "Open Sans";
  position: absolute;
  background-color: white;
  margin-top: -15px;
  z-index: 5;
}

.suggestion-item {
  padding: 5px;
  cursor: pointer;
  margin-bottom: 0;
}

.suggestion-item:hover {
  background-color: lightgray;
}

.state-sel {
  width: 412px;
}

.state-sel select[name="Province"] {
  height: 42px;
}
</style>
