<template>
  <fragment>
    <div class="grid space-between">
      <div class="overlay-loader" v-if="showOverLayLoader">
        <vue-simple-spinner />
      </div>
      <div class="col-12" v-if="cDisplayToast">
        <Message class="toast" @close="closeToast" :severity="toastSeverity"> {{ toastMessage }}</Message>
      </div>
      <div class="col-5">
        <p class="label">Filters</p>
        <base-filter name="Driver Status" :options="partnerStatusFilterOptions" @onFilter="onFilterChange((selectedPartnerStatuses = $event))" />
        <base-filter name="Recent Drivers" :options="partnersApprovedLastWeek" @onFilter="onFilterChange((recentPartners = $event))" />
        <base-filter ref="stageFilter" type="radio" name="Maintenance Alert" :options="maintenanceAlertFilterOptions" @onFilter="onFilterChange((selectedMaintenanceAlerts = $event))" />
        <location-filter name="Zones" :options="locationsFilterOptions" min-width="100px" @onFilter="onFilterChange((selectedLocation = $event))" />
        <button class="button w-button-search" :class="isLoading && 'disable-btn'" @click="getPartnersList()">Search</button>
      </div>
      <div class="col-7 flex inherit-flex">
        <!-- <button class="button w-button-primary" @click="wOpenModal('bulk-activate-partner-modal')">Activate</button> -->
        <!-- <button class="button secondary w-button-secondary" @click="wOpenModal('bulk-deactivate-partner-modal')">Deactivate</button> -->
        <div class="col-0.5" style="padding-right: 0px" @click="wOpenCommsFlyout('SMS')">
          <img src="@/assets/images/logos/walmart-sms.svg" />
        </div>
        <div class="col-0.5" style="padding-right: 0px" @click="wOpenCommsFlyout('EMAIL')">
          <img src="@/assets/images/logos/walmart-email.svg" />
        </div>
        <div class="col-2" style="padding-right: 0px">
          <div class="search-container">
            <input v-model="searchTerm" class="deposit-search" type="search" placeholder="Search Driver Name" />
            <button type="submit"><span v-if="searchIcon()" class="icon-x deposit-icon" @click="resetSearch"></span> <span v-else class="icon-search deposit-icon"></span></button>
          </div>
        </div>
      </div>
    </div>
    <div class="onboarding-partners">
      <div v-if="hasPartners" class="module location-header" style="margin: 0" :class="{ 'paginator-align': totalCount > perPage }">
        <div class="checkbox-custom" style="margin: 0">
          <input id="select-all" v-model="wSelectAllPartners" type="checkbox" />
          <label for="select-all"
            ><span class="label-container"
              ><b>Select All ({{ totalCount }} Partners)</b></span
            >
          </label>
        </div>
      </div>
      <data-table
        :sticky-header="true"
        :headers="headers"
        :loading="loader"
        :entries="filteredPartners"
        :pagination-total-count="totalCount"
        :pagination-per-page="perPage"
        :pagination-current-page="currentPage"
        @onPaginate="fetchData($event)"
        @onChangePerPage="onChangeCount($event)"
      >
        <template v-if="false" #checkbox="{ item }">
          <div>
            <input :id="item.partnerID" v-model="singleChecks" :value="item.partnerID" type="checkbox" class="select-all-input" @change="onSelectingPartner" />
          </div>
        </template>
        <template #name="{ item }">
          <a @click="openFlyout(item)">{{ item.name }}</a>
        </template>
        <template #location="{ item }">
          <p v-for="location in item.locations" :key="location.id">{{ location.name }}</p>
        </template>
        <template #approvalDate="{ item }">
          <p>{{ item.approvalDate }}</p>
        </template>
        <template #status="{ item }">
          <p>{{ item.partnerStatus }}</p>
        </template>
        <template #step="{ item }">
          <p>
            <i v-if="item.alertType == 'alert'" class="alert icon-alert-octagon"
              ><span class="main-alert">{{ item.maintenanceAlert }}</span></i
            >
            <i v-if="item.alertType == 'warning'" class="warning icon-alert-triangle"
              ><span class="main-alert">{{ item.maintenanceAlert }}</span></i
            >
            <span v-if="item.alertType == 'none'">{{ item.maintenanceAlert }}</span>
          </p>
        </template>
        <template #alertDate="{ item }">
          <p v-if="!item.alertDate">---</p>
          <p v-else>{{ item.alertDate | formatDate }}</p>
        </template>
      </data-table>
      <div id="bulk-activate-partner-modal" class="modal" aria-hidden="true">
        <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
          <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby" style="min-height: 300px">
            <div class="modal-content" style="text-align: center">
              <div class="module">
                <span><img src="@/assets/images/logos/walmart-info.svg" /></span>
              </div>
              <div class="module">
                <h4>Are you sure you want to activate partners?</h4>
                <p>You've selected {{ wSelectAllPartners ? totalCount : 0 }} partners to be activated, and they can't be recovered</p>
              </div>
              <div class="button-group close-top">
                <a class="button w-button-primary" data-micromodal-close @click="confirmationModal('ACTIVE')">Yes, Activate</a>
                <a class="button secondary w-button-secondary" style="margin-left: 20px" data-micromodal-close>No, Cancel</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="bulk-deactivate-partner-modal" class="modal" aria-hidden="true">
        <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
          <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby" style="min-height: 300px">
            <div class="modal-content" style="text-align: center">
              <div class="module">
                <span><img src="@/assets/images/logos/walmart-alert-circle.svg" /></span>
              </div>
              <div class="module">
                <h4>Are you sure you want to deactivate partners?</h4>
                <p>You've selected {{ wSelectAllPartners ? totalCount : 0 }} partners to be deactivated, and they can't be recovered</p>
              </div>
              <div class="button-group close-top">
                <a class="button" style="background: #c2272c; color: #ffffff" data-micromodal-close @click="confirmationModal('INACTIVE')">Yes, Deactivate</a>
                <a class="button secondary" style="margin-left: 20px" data-micromodal-close>No, Cancel</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bulk-communications-flyout
      :bulk-obj="partnerForBulkComm"
      :on-close="closeBulkComms"
      :display-editor="displayEmailEditor"
      :comms-type="commsFlyoutType"
      @sendEmail="sendEmail"
      @sendText="sendSMS"
    />
    <partner-flyout :data="selectedPartner" @statusupdated="onFlyoutStatusChange" @cbcverified="onFlyoutStatusChange" @vehicleDetailsUpdated="onUpdatingVehicleDetails" />
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import { BaseFilter, DataTable, TableHeader, Direction, LocationFilter } from "@/components";
import { mapActions, mapGetters } from "vuex";
import { DateTime, Interval } from "luxon";
import PartnerFlyout from "@/modules/admin/onboarding/flyouts/PartnerFlyout";
import { BulkCommunicationsFlyout } from "@/modules/shared/communications";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/filters";
// import micromodal from "micromodal";
import Message from "primevue/message";
import { decode } from "@/filters";
import VueSimpleSpinner from "vue-simple-spinner";

export default {
  components: { Fragment, BaseFilter, DataTable, PartnerFlyout, BulkCommunicationsFlyout, LocationFilter, Message, VueSimpleSpinner },
  data: () => ({
    searchTerm: "",
    selectedPartnerStatuses: [],
    selectedMaintenanceAlerts: [],
    recentPartners: [],
    selectedLocation: [],
    isLoading: false,
    toastMessage: "",
    toastSeverity: "",
    displayToast: false,
    totalCount: 0,
    currentPage: 0,
    perPage: 50,
    offset: 0,
    selectedPartner: {}, //this holds the complete (selected)partner data
    onboardingPartnerView: [], // this holds all the partners for the given serach filter
    partnerForBulkComm: [],
    isFlyoutLoading: true,
    wSelectAllPartners: false,
    alertDate: new Date().toISOString(), //for maintannace alerts
    commsFlyoutType: "", // sms or email
    displayEmailEditor: true,
    singleChecks: [],
    showOverLayLoader: false,
  }),
  computed: {
    ...mapGetters(["isFlyoutShowing", "getUserID", "getSelectedEmployee"]),
    loader() {
      return this.isLoading;
    },
    locationsFilterOptions() {
      return [...this.$store.state.onboarding.candidatesTab.locationsFilterOptions];
    },
    cDisplayToast() {
      return this.displayToast;
    },
    partnerStatusFilterOptions() {
      return [
        { id: "op-active", name: "ACTIVE", label: "Active", checked: false },
        { id: "op-inactive", name: "INACTIVE", label: "Inactive", checked: false },
        { id: "op-idle", name: "IDLE", label: "Idle", checked: false },
        { id: "op-newly-approved", name: "NEWLY_APPROVED", label: "Newly Approved", checked: false },
        { id: "op-blocked", name: "BLOCKED", label: "Blocked", checked: false },
      ];
    },
    partnersApprovedLastWeek() {
      return [{ id: "op-last-week-approved", name: "LAST_WEEK_APPROVED_PARTNERS", label: "Last Week Approved Partner", checked: false }];
    },
    maintenanceAlertFilterOptions() {
      return [
        { id: "op-auto-insurance", name: "autoInsurance", label: "Auto Insurance" },
        { id: "op-drivers-license", name: "driversLicense", label: "Drivers License" },
        { id: "op-unverified-insurance", name: "unverifiedInsurance", label: "Unverified Insurance" },
      ];
    },
    hasPartners() {
      return this.filteredPartners && this.filteredPartners.length > 0;
    },
    filteredPartners() {
      //feeds the data grid
      return this.onboardingPartnerView.map((partner) => {
        return {
          id: partner.partnerLocationID,
          notes: partner.notes,
          name: `${partner.firstName} ${partner.lastName}`.trim(),
          email: partner.email,
          phone: partner.phone ? partner.phone : "",
          locations: [{ id: partner.locationID, name: partner.locationName }],
          locationID: partner?.locationID ? partner.locationID : null,
          partnerID: partner.partnerID,
          partnerStatus: this.sentenceCase(partner.partnerStatus ?? "NO STATUS"),
          approvalDate: partner?.approvalDate ? this.format(partner.approvalDate?.split("T")[0]) : "",
          userID: partner?.userID ? partner.userID : "",
          lastPay: partner?.lastPay ? partner.lastPay : "",
          insuranceExpiry: partner?.insuranceExpiry ? partner.insuranceExpiry : "",
          driversLicense: partner?.DLExpiration ? partner.DLExpiration : "",
          maintenanceAlert: this.setMaintenanceAlerts(partner),
          alertDate: this.setMaintenanceAlerts(partner) === "None" ? "" : this.alertDate,
          alertType: this.getAlertType(this.setMaintenanceAlerts(partner)),
          insuranceUpdatedDate: partner.last_insurance_upload_on_ts ? this.format(partner.last_insurance_upload_on_ts) : "---",
        };
      });
    },
    headers() {
      return [
        new TableHeader({ headerClass: "cell2", cellClass: "bulk-checkbox-actions", name: "checkbox" }),
        new TableHeader({
          name: "name",
          label: "Drivers Name",
          cellContents: "name",
          cellClass: "bulk-checkbox-actions",
          headerClass: "cell15",
          sortable: true,
        }),
        new TableHeader({
          name: "location",
          label: "Zone",
          cellContents: "location",
          headerClass: "cell15",
          sortable: true,
        }),
        new TableHeader({
          name: "approvalDate",
          label: "Approval Date",
          cellContents: "approvalDate",
          headerClass: "cell10",
          sortable: true,
        }),
        new TableHeader({
          name: "status",
          label: "Driver Status",
          cellContents: "partnerStatus",
          headerClass: "cell10",
          sortable: true,
          sortFunction: this.sortPartnerStatus,
          sorted: true,
          direction: Direction.ASCE,
          cellClass: "time",
        }),
        new TableHeader({
          name: "step",
          label: "Maintenance Alert",
          cellContents: "step",
          headerClass: "cell22",
          cellClass: "step",
          sortable: true,
          sortFunction: this.sortStatus,
          sorted: true,
          direction: Direction.DESC,
        }),
        new TableHeader({
          name: "alertDate",
          label: "Exp Date",
          cellContents: "alertDate",
          headerClass: "cell10",
          sortable: true,
          cellClass: "alertDate",
        }),
        new TableHeader({
          name: "insuranceUpdatedDate",
          label: "Updated On",
          cellContents: "insuranceUpdatedDate",
          headerClass: "cell15",
          sortable: true,
          cellClass: "insuranceUpdatedDate",
        }),
      ];
    },
    startOfWeek() {
      const startweek =
        6 > DateTime.now().setZone("America/Los_Angeles").weekday ? DateTime.now().setZone("America/Los_Angeles").weekNumber - 1 : DateTime.now().setZone("America/Los_Angeles").weekNumber;
      return DateTime.utc().setZone("America/Los_Angeles").startOf("day").set({ weekNumber: startweek, weekday: 6 });
    },
    startOfPreviousWeek() {
      return DateTime.fromISO(this.startOfWeek).startOf("day").minus({ days: 7 });
    },
    endOfPreviousWeek() {
      return DateTime.fromISO(this.endOfWeek).endOf("day").minus({ days: 7 });
    },
    endOfWeek() {
      return DateTime.fromISO(this.startOfWeek).endOf("day").plus({ days: 6 });
    },
  },
  methods: {
    ...mapActions(["showFlyout"]),
    searchIcon() {
      return this.searchTerm !== "";
    },
    getPartnersList() {
      if (this.isLoading) return;
      this.isLoading = true;
      if (!this.selectedLocation.length) this.selectedLocation = this.locationsFilterOptions;
      restApi
        .post("/employee/onboardingPartnerViewV3", encodeWithParam(this.partnersListParams()))
        .then((data) => {
          this.onboardingPartnerView = data.data.result;
          this.totalCount = Number(data.data?.total.total_partner_count);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.errorToast(`Error occured: ${err.message}`);
        });
    },
    partnersListParams() {
      let backDate = new Date("1990", "01", "01");
      let futureDate = new Date("2050", "01", "01");
      return {
        phone: this.searchMatch("phone"),
        email: this.searchMatch("email"),
        fullName: this.searchMatch("fullName"),
        userID: this.getSelectedEmployee.id,
        locationIds: this.getLocationFilterData(),
        driverLicenseExpiryDate: this.findMaintenanceAlerts("drivers license"),
        insuranceExpiryDate: this.findMaintenanceAlerts("auto insurance"),
        insuranceVerified: this.selectedMaintenanceAlerts.includes("unverified insurance") ? [false] : [true, false],
        status: this.filteredStatus(),
        lastWeekStartDate: this.recentPartners.length > 0 ? this.startOfPreviousWeek : backDate,
        lastWeekEndDate: this.recentPartners.length > 0 ? this.endOfPreviousWeek : futureDate,
        limit: this.perPage,
        offset: this.offset,
      };
    },

    searchMatch(filter) {
      let searchData = "";
      let isPhone = /^[0-9]*$/;
      let isEmail = /\S+@\S+\.\S+/;
      if (filter === "email") {
        if (isEmail.test(this.searchTerm)) {
          searchData = this.searchTerm;
        } else {
          searchData = "";
        }
      }
      if (filter === "phone") {
        if (isPhone.test(this.searchTerm)) {
          searchData = this.searchTerm;
        } else {
          searchData = "";
        }
      }
      if (filter === "fullName") {
        if (!isEmail.test(this.searchTerm) && !isPhone.test(this.searchTerm)) {
          searchData = this.searchTerm;
        } else {
          searchData = "";
        }
      }
      return searchData;
    },
    sortPartnerStatus() {
      return (partner1, partner2) => {
        const partner1Urgency = this.computeStatus(partner1);
        const partner2Urgency = this.computeStatus(partner2);
        return partner1Urgency - partner2Urgency;
      };
    },

    computeStatus(partner) {
      let status = partner.partnerStatus.toLowerCase();
      switch (status) {
        case "active":
          return 1;
        case "inactive":
          return 2;
        case "idle":
          return 3;
        case "newly_approved":
          return 4;
        case "blocked":
          return 5;
      }
    },
    resetSearch() {
      this.searchTerm = "";
      this.currentPage = 1;
    },
    onChangeCount(event) {
      this.perPage = event.perPage;
    },
    fetchData(event) {
      this.currentPage = Number(event.pageNumber);
      this.offset = event.offset;
      this.getPartnersList();
    },
    openFlyout(partner) {
      this.getFlyoutData(partner);
    },
    onFilterChange() {
      // console.log("filter changed: ", data);
    },
    onFlyoutStatusChange(data) {
      if(data) this.selectedPartner[data.field] = data.data;
      this.getPartnersList();
    },
    onUpdatingVehicleDetails(data) {
      this.selectedPartner = {
        ...this.selectedPartner,
        vehicle_make: data.make,
        vehicle_color: data.color,
        vehicle_year: data.year,
        vehicle_plate: data.licensePlate,
        vehicle_model: data.model,
      };
    },
    getLocationFilterData() {
      return this.selectedLocation.map((item) => {
        return item.id;
      });
    },
    getStatusFilterData() {
      return [];
    },
    getMaintenanceFilterData() {
      return [];
    },
    onSelectingPartner() {
      // each selected paartner will pushed to
      // this.singleChecks array
    },
    resetPage() {
      this.selectedPartnerStatuses = [];
      // this.selectedLocations = [];// ?
      this.selectedLocation = []; // this is loactions filter data
      this.selectedMaintenanceAlerts = [];
      this.recentPartners = [];
      this.resetPaginator();
      //  this.$apollo.queries.onboardingPartnerView.refresh();
    },
    closeComms() {
      this.singleChecks = [];
      this.partnerForBulkComm = [];
      this.resetPage();
      this.showFlyout("");
    },
    filteredStatus() {
      if (this.selectedPartnerStatuses.length > 0) {
        return this.selectedPartnerStatuses.map((item) => {
          const formatting = item.replaceAll(" ", "_");
          return formatting.toUpperCase();
        });
      } else {
        return ["ACTIVE", "INACTIVE", "IDLE", "NEWLY_APPROVED", "BLOCKED"];
      }
    },
    format(date) {
      let dateToFormat = date;
      dateToFormat = DateTime.fromISO(date);
      return dateToFormat.toFormat("dd LLL yyyy");
    },
    sentenceCase(string) {
      if (string === "NEWLY_APPROVED") {
        return "Newly Approved";
      }
      let removeCharacters = string.replaceAll("_", " ");
      return removeCharacters.charAt(0) + removeCharacters.slice(1).toLowerCase();
    },
    getAlertType(alertMessage) {
      switch (alertMessage) {
        case "Verify Auto Insurance(1 more)":
          return "warning";
        case "Drivers License Expiring(1 more)":
          return "warning";
        case "Drivers License Expiring":
          return "warning";
        case "Verify Auto Insurance":
          return "warning";
        case "Expired Drivers License":
          return "alert";
        case "Verify Auto Insurance update":
          return "alert";
        case "Expired Drivers License(1 more)":
          return "alert";
        case "Verify Auto Insurance update(1 more)":
          return "alert";
        case "CBC Consider":
          return "alert";
        case "Insurance Not Verified":
          return "alert";
        case "None":
          return "none";
      }
    },
    // sets the Maintanance Alert status
    setMaintenanceAlerts(partner) {
      if (!partner.insurance_verified) {
        this.alertDate = partner?.insuranceExpiry;
        return "Insurance Not Verified";
      }
      let insuranceExpiry = new Date(partner.insuranceExpiry);
      let driverLicenceExpiry = new Date(partner.DLExpiration);
      let insuranceExpirayDifference = Interval.fromDateTimes(DateTime.now(), insuranceExpiry).length("days");
      let driversLicenceExpirayDifference = Interval.fromDateTimes(DateTime.now(), driverLicenceExpiry).length("days");
      if (partner.partnerCBCResult === "consider") {
        return "CBC Consider";
      } else {
        if (Math.round(insuranceExpirayDifference) <= 14 || Math.round(driversLicenceExpirayDifference) <= 14) {
          if (Math.round(insuranceExpirayDifference) <= 14 && Math.round(driversLicenceExpirayDifference) <= 14) {
            if (insuranceExpiry > driverLicenceExpiry) {
              this.alertDate = insuranceExpiry.toISOString();
              return "Verify Auto Insurance(1 more)";
            } else {
              this.alertDate = driverLicenceExpiry.toISOString();
              return "Drivers License Expiring(1 more)";
            }
          } else {
            if (insuranceExpiry > driverLicenceExpiry) {
              this.alertDate = driverLicenceExpiry.toISOString();
              if (isNaN(driversLicenceExpirayDifference)) {
                return "Expired Drivers License";
              }
              return "Drivers License Expiring";
            } else {
              this.alertDate = insuranceExpiry.toISOString();
              if (isNaN(insuranceExpirayDifference)) {
                return "Verify Auto Insurance update";
              }
              return "Verify Auto Insurance";
            }
          }
        } else if (isNaN(insuranceExpirayDifference) || isNaN(driversLicenceExpirayDifference)) {
          if (isNaN(insuranceExpirayDifference) && isNaN(driversLicenceExpirayDifference)) {
            if (insuranceExpiry > driverLicenceExpiry) {
              this.alertDate = driverLicenceExpiry.toISOString();
              return "Expired Drivers License(1 more)";
            } else {
              this.alertDate = insuranceExpiry.toISOString();
              return "Verify Auto Insurance update(1 more)";
            }
          }
          if (insuranceExpiry > driverLicenceExpiry) {
            this.alertDate = driverLicenceExpiry.toISOString();
            return "Expired Drivers License";
          } else {
            this.alertDate = insuranceExpiry.toISOString();
            return "Verify Auto Insurance update";
          }
        } else {
          return "None";
        }
      }
    },
    findMaintenanceAlerts(searchString) {
      let fortNight = new Date(Date.now() + 12096e5);
      let futureDate = new Date();
      if (this.selectedMaintenanceAlerts.indexOf(searchString) !== -1) {
        return fortNight;
      } else {
        futureDate.setDate(futureDate.getDate() + 21900);
        return futureDate;
      }
    },
    resetPaginator() {
      this.totalCount = 0;
      this.currentPage = 1;
      this.offset = 0;
    },
    sortStatus() {
      return (candidate1, candidate2) => {
        const candidate1Urgency = this.computeUrgency(candidate1);
        const candidate2Urgency = this.computeUrgency(candidate2);
        return candidate1Urgency - candidate2Urgency;
      };
    },
    async getFlyoutData(partner) {
      this.showOverLayLoader = true;
      let params = {
        partnerID: partner.partnerID,
        candidateID: partner.id,
        clientLocationPartnerID: partner.id,
        clientID: "c8c0aa58-0a23-4ae4-9e74-4ea34fefe5fb",
        clientLocationID: partner.locationID,
      };
      try {
        let resp = await restApi.post("partner/getPartnerDetails", encodeWithParam(params));
        if (!resp.data.success) throw new Error(resp.data.message ?? "Could not fetch partner data");
        let partnerData = resp.data.PartnerDetails[0];
        let insData = this.getInsuranceFileId(resp.data.docs);

        this.selectedPartner = {
          candidateID: partnerData?.id ? partnerData.id : "",
          partnerID: partnerData?.partnerID ? partnerData.partnerID : "",
          locationID: partnerData?.locationID ? partnerData.locationID : "",
          firstName: partnerData?.firstName ? partnerData.firstName : "",
          lastName: partnerData?.lastName ? partnerData.lastName : "",
          name: `${partnerData.firstName} ${partnerData.lastName}`,
          phone: partnerData?.phone ? partnerData.phone : "",
          email: partnerData?.email ? partnerData.email : "",
          approvalDate: partnerData?.approvalDate ? partnerData.approvalDate : "",
          externalID: partnerData?.externalID ? partnerData.externalID : "",
          locationName: partnerData?.locationName ? partnerData.locationName : "",
          dateOfBirth: partnerData?.dateOfBirth ? partnerData.dateOfBirth : "",
          insuranceExpiry: partnerData?.insuranceExpiry ? partnerData.insuranceExpiry : "",
          insuranceVerified: partnerData.insuranceVerified,
          userID: partnerData?.userID ? partnerData.userID : "",
          username: partnerData?.username ? partnerData.username : "",
          dlNumber: partnerData?.number ? partnerData.number : "",
          dlExpiration: partnerData?.expiration ? partnerData.expiration : "",
          dlState: partnerData?.state ? partnerData.state : "",
          status: partnerData?.status ? partnerData.status : "",
          reportConfig: resp.data.CheckrReportConfig.length ? JSON.parse(resp.data.CheckrReportConfig[0].value) : { rootCheckrLink: "", checkrTestQuery: "" },
          cbcDate: resp.data.CBCDate[0] ? resp.data.CBCDate[0].reportDate : [],
          notesList: resp.data.Notes.length ? resp.data.Notes[0].notesList : [],
          lastPay: resp.data.lastPay[0] ? resp.data.lastPay[0].endDate : [],
          decoded: partnerData.encodedData ? decode(partnerData.encodedData) : "",
          insuranceFileId: insData.fileID,
          documentTemplateID: insData.documentTemplateID,
          vehicle_make: partnerData.vehicle_make,
          vehicle_color: partnerData.vehicle_colour,
          vehicle_year: partnerData.vehicle_year,
          vehicle_plate: partnerData.vehicle_licence_plate,
          vehicle_model: partnerData.vehicle_model,
        };
        this.showOverLayLoader = false;
        this.showFlyout("partner-flyout");
      } catch (err) {
        this.showOverLayLoader = false;
        this.errorToast(err.message);
      }
    },
    getInsuranceFileId(docs) {
      try {
        let data = docs.filter((item) => item.title.toLowerCase() === "insurance")[0];
        return { fileID: data.fileID, documentTemplateID: data.id };
      } catch (error) {
        return {};
      }
    },
    computeUrgency(partner) {
      if (partner.alertType === "alert") {
        return 3;
      }
      if (partner.alertType === "warning") {
        return 2;
      }
      return 1;
    },
    wOpenCommsFlyout(type) {
      if (this.wSelectAllPartners) {
        this.commsFlyoutType = type;
        this.displayEmailEditor = true;
        this.partnerForBulkComm = [];
        const filtered = this.filteredPartners;
        filtered.forEach((item) => {
          let obj = {
            name: item.name,
            partnerID: item.partnerID,
          };
          this.partnerForBulkComm.push(obj);
        });
        this.showFlyout("bulk-comms-flyout");
      } else {
        this.errorToast("Click on select all");
      }
    },
    closeBulkComms() {
      this.singleChecks = [];
      this.partnerForBulkComm = [];
      this.wSelectAllPartners = false;
      this.showFlyout("");
    },
    // wOpenModal(id) {
    //   if (this.wSelectAllPartners) {
    //     micromodal.show(id);
    //   } else {
    //     this.errorToast("Click on select all");
    //   }
    // },
    confirmationModal(status) {
      if (this.wSelectAllPartners) {
        const params = this.partnersListParams();
        params.limit = 0;
        params.offset = 0;
        const payload = {
          filterData: params,
          status: status,
          action: "STATUS_CHANGE",
        };
        restApi
          .post("/onboard/bulkAction", encodeWithParam(payload))
          .then(({ data }) => {
            this.successToast(data);
          })
          .catch((error) => {
            this.errorToast(error.message);
          });
      } else {
        this.errorToast("Click on select all");
      }
    },
    sendEmail(event) {
      const params = this.partnersListParams();
      params.limit = 0;
      params.offset = 0;
      const payload = {
        html: event.html,
        subject: event.subject,
        action: "EMAIL",
        userID: this.getUserID,
        filterData: params,
        type: "PARTNER",
      };
      restApi.post("/onboard/bulkAction", encodeWithParam(payload)).then(({ data }) => {
        this.successToast(data);
      });
      this.displayEmailEditor = false;
    },
    sendSMS(event) {
      const params = this.partnersListParams();
      params.limit = 0;
      params.offset = 0;
      const payload = {
        body: event.body,
        sendDate: event.sendDate,
        action: "SMS",
        userID: this.getUserID,
        filterData: params,
        type: "PARTNER",
      };
      restApi.post("/onboard/bulkAction", encodeWithParam(payload)).then(({ data }) => {
        this.successToast(data);
      });
      this.displayEmailEditor = false;
    },
    successToast(data) {
      this.displayToast = true;
      this.toastSeverity = "success";
      this.toastMessage = data.message;
      this.wSelectAllPartners = false;
    },
    errorToast(message) {
      this.toastMessage = message;
      this.displayToast = true;
      this.toastSeverity = "error";
    },
    closeToast() {
      this.displayToast = false;
    },
  },
};
</script>
<style scoped>
.warning {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #a85d07;
}
.alert {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #c2272c;
}
.main-alert {
  padding-left: 2px;
  font-family: "Open Sans", sans-serif;
}
.inherit-flex {
  flex: inherit !important;
}
.w-button-primary {
  background: #0071dc;
}
.w-button-secondary {
  border-color: #0071dc !important;
  color: #0071dc;
}
button.w-button-search {
  height: 40px;
  background: #0071dc;
}
button.w-button-primary,
button.w-button-secondary {
  height: 40px;
  margin-left: 12px;
}
button:hover.w-button-primary,
button:hover.w-button-search,
a:hover.w-button-primary {
  background: #0071dc;
}
.disable-btn {
  opacity: 0.5;
  pointer-events: none;
}
.toast {
  width: 40%;
  margin: -18px auto 0 auto;
}
.p-message.p-message-error {
  background: #c2272c;
  color: #ffffff;
}
.p-message.p-message-success {
  background: #2a8703;
  color: #ffffff;
}
.paginator-align {
  margin-bottom: -40px !important;
}
.overlay-loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 6;
  background-color: rgba(0, 0, 0, 0.7);
  display: grid;
  align-items: center;
}
</style>
